import Layout from '../../components/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from '../../components/builders/WidgetBuilder'
import { IPageContext } from 'Root/src/utils/data/buildTypedPage'
import PuxMetaTagsGatsby from 'Root/src/utils/PuxMetaTagsGatsby'
import { BasePage, PuxLandingPageType } from 'Root/src/queries/page-queries'

const PuxHomePage: FunctionComponent<PuxLandingPageType> = ({
  data,
  pageContext,
}) => {
  const headerData = pageContext.headerData
  const footerData = pageContext.footerData
  //const pageData = data?.orchard?.puxLandingPage[0]

  return (
    <Layout
      headerData={headerData}
      footerData={footerData}
    // localizedPath={pageData.localization?.localizations}
    >
      <WidgetBuilder widgetBuilderData={pageContext?.widgets.flow} />
    </Layout>
  )
}

export default PuxHomePage

export function Head(props: HeadProps<never, IPageContext<BasePage>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}